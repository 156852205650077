.section {
  margin-top: 3rem;
  margin-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @include media-breakpoint-only(md) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &--xl {
    margin-top: 9rem;
    margin-bottom: 9rem;

    @include media-breakpoint-down(sm) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }

    @include media-breakpoint-only(md) {
      margin-top: 9rem;
      margin-bottom: 9rem;
    }
  }

  &--lg {
    margin-top: 6rem;
    margin-bottom: 6rem;

    @include media-breakpoint-down(sm) {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    @include media-breakpoint-only(md) {
      margin-top: 4.5rem;
      margin-bottom: 4.5rem;
    }
  }

  &--sm {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @include media-breakpoint-only(md) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

.section-group {
  [class^="section"] {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
