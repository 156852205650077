.nav-container {
  box-shadow: inset 0px -2px 0px 0px $gray-200;
  overflow-x: scroll;
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.nav-underline {
  flex-wrap: nowrap;

  .nav-item a,
  a.nav-item {
    border-bottom: 2px solid $gray-200;
    padding-top: 18px;
    padding-bottom: 18px;

    white-space: nowrap;
  }

  .nav-item a.active,
  a.nav-item.active {
    border-color: $primary;
  }
}
