.menu-developer {
  padding: 30px 30px 0 30px;

  > * {
    padding-bottom: 30px;
  }

  @include media-breakpoint-up($mega-breakpoint-up) {
    display: flex;
    padding: 30px 40px 0 40px;

    > * {
      width: 200px;
    }

    > :first-child {
      margin-right: 30px;
    }
  }

  @include media-breakpoint-only(lg) {
    padding: 30px 30px 0 30px;
  }
}

.menu-product {
  padding: 2rem;

  @include media-breakpoint-up($mega-breakpoint-up) {
    width: 28rem;
    padding: 2.5rem;
  }
}
