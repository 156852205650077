ul.list-check {
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    position: relative;
    padding-left: 55px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  > li::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    display: block;
    width: 30px;
    height: 30px;
    background-color: rgba($primary, 0.1);
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="%2322B99A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 99px;
  }

  &.list-check--solid > li::before {
    background-color: $primary;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
  }

  ul {
    padding: 10px 0 10px 20px;
    list-style-type: disc;

    li {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &.list-check--inv {
    li {
      color: $text-inv-lead;
    }
  }
}

// List group

.list-group-item,
.list-group-item a {
  display: block;
  //color: $text-lead;
  //font-weight: $font-weight-bold;
}

.list-group-item a {
  //border: 0;

  &:hover {
    //color: $primary;
  }
}

.list-group.list-group-flat {
  .list-group-item {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    padding-right: 0;
    padding-left: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.list-inline.list-inline--lg {
  .list-inline-item:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.icon-list {
  .icon-list-item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 21px;

      .icon-list-text {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 1px;
          background: $gray-300;
        }
      }
    }
  }

  .icon-list-text {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 30px;
  }
}

.list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .list-item {
    margin: 6px 0;
  }

  .list-link {
    display: block;
    margin: 6px 0;
    padding: 5px 10px;
    border-radius: 4px;

    &.active,
    &:focus {
      background: $gray-100;
    }

    &:active {
      background: $gray-200;
    }
  }
}

.list.list--store {
  .list-link {
    position: relative;
    padding-left: 40px;

    &::before {
      content: '-';
      position: absolute;
      top: 4px;
      left: 14px;
      color: $text-muted;
    }
  }
}

.list.list--checkbox {
  label {
    margin-left: 10px;
    padding-left: 10px;
  }

  .list-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
