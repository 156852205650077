.accordion {}

.accordion-item:not(:last-child) {
  border-bottom: 1px solid $gray-200;
}

.accordion-header {
  position: relative;
  padding: 18px 0 18px 50px;
  @extend .lead-bold;
  color: $text-lead;
  cursor: pointer;

  &:active {
    background: $gray-100;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%2322B99A" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform .2s;
  }

  &.collapsed::before {
    transform: translateY(-50%) rotate(45deg);
  }
}

.accordion-body {
  padding: 20px 0 40px 50px;
}


// Dark mode

.accordion.accordion--inv {
  .accordion-header {
    color: $text-inv-lead;
    &:active { background: rgba(#fff, .05); }
  }

  .accordion-body {
    color: $text-inv-base;
  }

  .accordion-item {
    border-color: rgba(#fff, .1);
  }
}
