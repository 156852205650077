.quote {
  display: flex;
  flex-direction: column;

  &.quote--center {
    align-items: center;
    .quote-body { text-align: center; }
  }
}

.quote-mark::before {
  content: '\201C';
  display: block;
  color: $primary;
  font-size: 6rem;
  line-height: 1;
  font-family: sans-serif;
  font-weight: bold;
  height: 5rem;
  transform: translateX(-5px);
}

.quote-header {
  margin-bottom: 4rem;
}

.quote-body {
  @extend .lead;
  flex-grow: 1;
  margin-bottom: 4rem;
}

.quote-footer {
  display: flex;
  align-items: center;
}

.quote-avatar {
  margin-right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 999px;
  overflow: hidden;

  > img {
    max-width: 100%;
  }
}
