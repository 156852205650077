.bg-none {
  background: transparent;
}

// Extra wrapper for fixed width

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    @each $size in map-keys($wrapper-sizes) {
      .wrapper--#{$breakpoint}-#{$size} {
        max-width: map-get($wrapper-sizes, $size);
      }
    }
  }
}

.square {
  position: relative;
  overflow: hidden;

  .square-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80%;

    .category-title {
      font-size: 1.2rem;
    }
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}


// Hamburger
.hamburger:focus {
  outline: none;
}


// Pagination
.pagination {
  .page-link {
    border-radius: 4px !important;
  }

  .page-item:not(:first-child) {
    margin-left: 4px;
  }

  .page-item:not(:last-child) {
    margin-right: 4px;
  }
}


// Icons
.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #000;
  fill: #000;

  &.icon--sm {
    width: 1rem;
    height: 1rem;
  }

  &.icon--lg {
    width: 2rem;
    height: 2rem;
  }

  &.icon--xl {
    width: 3rem;
    height: 3rem;
  }
}

// Extra badges
.badge-outline-base {
  border: 1px solid $gray-300;
}

.badge-outline-primary {
  border: 1px solid $gray-300;
  color: $primary;
}

.badge-gray {
  background-color: $gray-300;
}


.floating-img {
  width: 100%;
  height: 100%;

  @include media-breakpoint-down($mega-breakpoint-down) {
    img {
      max-width: 100%;
    }
  }

  @include media-breakpoint-up($mega-breakpoint-up) {
    img {
      float: left;
    }

    &.floating-img--right {
      img {
        float: right;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.shadow-blur {
  box-shadow: $box-shadow-blur;
}

.shadow-blur-lg {
  box-shadow: $box-shadow-blur-lg;
}


.rounded-pill {
  border-radius: 999px;
}

.avatar {
  display: flex;
  align-items: center;

  .avatar-img {
    width: 45px;
    height: 45px;
    border-radius: 99px;
    overflow: hidden;
  }

  .avatar-name {
    margin-left: 20px;
  }

  &.avatar-xl {
    .avatar-img {
      width: 145px;
      height: 145px;
    }
  }
}

.category-image {
  max-width: 50%;
}
