// Colors

$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #7b848c;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;

$blue:    #007bff;
$indigo:  #6610f2;
$navy:    #1E2E3E;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #22B99A;
$cyan:    #17a2b8;

$primary:       $teal;
$secondary:     $navy;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

// Options

$enable-responsive-font-sizes: true;

// Spaces

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

// Grid

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  //xl: 1470px
  xl: 1160px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  //xl: 1410px
  xl: 1100px
);

// Extra sized

@media (min-width: 1600px) {
  .container,
  .container-lg {
    max-width: 1410px !important;
  }
}

@media (min-width: map_get($grid-breakpoints, 'lg')) and (max-width: 1599px) {
  html {
    font-size: 13px;
  }
}

// Body & Typography

$line-height-base: 1.7;

$body-color: $gray-600;

$text-base: $body-color;
$text-muted: $gray-500;
$text-lead: $gray-900;

$text-inv-base: rgba(255, 255, 255, 0.65);
$text-inv-muted: rgba(255, 255, 255, 0.45);
$text-inv-lead: #fff;

$link-color: inherit;
$link-hover-color: $primary;
$link-hover-decoration: none;

$secondary-font: 'Sora', sans-serif;

$paragraph-margin-bottom:   2.5rem;

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.2;
$font-size-sm:                $font-size-base * .875;
$font-size-xs:                $font-size-base * .75;

$lead-font-size:              $font-size-base * 1.25;
$large-font-size:             $font-size-lg; // custom
$small-font-size:             $font-size-sm;

$h1-font-size:                $font-size-base * 3.5;
$h2-font-size:                $font-size-base * 3;
$h3-font-size:                $font-size-base * 2.2;
$h4-font-size:                $font-size-base * 1.8;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      map_get($spacers, 4);
$headings-font-family:        $secondary-font;
$headings-font-weight:        700;
$headings-line-height:        1.1;
$headings-color:              $text-lead;

$display1-size:               5rem;
$display2-size:               4.8rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             $headings-font-weight;
$display2-weight:             $headings-font-weight;
$display3-weight:             $headings-font-weight;
$display4-weight:             $headings-font-weight;

$spacing-margin-bottom:      map_get($spacers, 5); // custom
$display-line-height:         1em;

$box-shadow-sm:               0 .15rem .15rem rgba(#000, .05);
$box-shadow:                  0 .5rem 1rem rgba(#000, .15);
$box-shadow-lg:               0 1rem 3rem rgba(#000, .175);
$box-shadow-blur:             0 .5rem 1rem rgba(#000, .04);
$box-shadow-blur-lg:          0 .5rem 1rem rgba(#000, .08);

// Buttons & Forms

$input-placeholder-color:     $text-muted;

$input-btn-padding-x:         1.2rem;
$input-btn-padding-y:         .88rem;
$input-btn-padding-y-lg:      1.5rem;
$input-btn-padding-x:         1.2rem;
$input-btn-padding-y-sm:      .8rem;
$input-btn-padding-x-sm:      .88rem;

$btn-padding-x:               2.8rem;
$btn-padding-x-lg:            2.8rem;

$btn-font-family:             $secondary-font;
$btn-font-weight:             500;

// Cards

$card-spacer-y:                     1rem;
$card-spacer-x:                     2rem;
$card-border-color:                 rgba(#000, .08);
$card-bg:                           none;

  // Breadcrumb

$breadcrumb-padding-y:              20px;
$breadcrumb-padding-x:              0;
$breadcrumb-font-size:              $small-font-size;
$breadcrumb-bg:                     transparent;
$breadcrumb-active-color:           $text-muted;
$breadcrumb-divider:                url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMSIgdmlld0JveD0iMCAwIDE4IDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxIiBmaWxsPSJibGFjayIgZmlsbC1vcGFjaXR5PSIwLjEzIi8+Cjwvc3ZnPgo=");
$breadcrumb-margin-bottom:          3px;

// Extra wrapper

$wrapper-sizes: (
        larger: 1200px,
        large: 900px,
        medium: 600px,
);

// Hamburger
$hamburger-padding-x: 24px;
$hamburger-padding-y: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-width: 30px;
$hamburger-types: (
        slider
);

// List group

$list-group-bg: none;
$list-group-border-color: rgba(#000, .09);

// Pagination

$pagination-padding-y: .9rem;
$pagination-padding-x: 1.2rem;
$pagination-padding-y-lg: 1.2rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-border-color: $gray-200;
$pagination-hover-color: $primary;
$pagination-hover-bg: #fff;
$pagination-hover-border-color: $primary;

$pagination-active-color: $primary;
$pagination-active-bg: #fff;
$pagination-active-border-color: $pagination-border-color;

// Badges

$badge-font-size: $font-size-xs;

// Mega menu

$mega-breakpoint-down: 'md';
$mega-breakpoint-up: 'lg';

// Navs

$nav-link-padding-y:                1rem;
$nav-link-padding-x:                1.5rem;
