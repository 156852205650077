// Custom variables
@import "variables";

// Bootstrap
@import '../../../../vendor/sylius/bootstrap-theme/assets/scss/index';

// Custom styles
@import "body";
@import "typography";
@import "section";
@import "quote";
@import "footer";
@import "list";
@import "accordion";
@import "card";
@import "form";
@import "breadcrumb";
@import "bg";
@import "nav";
@import "hero";
@import "mega-menu";
@import "mega-menu-customs";
@import "menu";
@import "drop";
@import "table";
@import "store";
@import "store-search";

@import "hamburgers/_sass/hamburgers/hamburgers";
@import "swiper/swiper";
@import "swiper/components/pagination/pagination";
