.store-home-course {
  @include media-breakpoint-up(xl) {
    position: absolute;
    top: 30px;
    right: 120px;
    max-width: 32rem;
  }
}

.store-home-vendor {
  @include media-breakpoint-up(xl) {
    margin-top: 60px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      width: 89%;
      height: 100%;
      background: $gray-100;
    }
  }
}

// Slider
.store-carousel {
  @include media-breakpoint-up(lg) {
    overflow: visible;
  }

  .store-carousel-container {
    @include media-breakpoint-up(lg) {
      overflow: visible;
    }
  }

  .swiper-slide {
    width: 100%;
    height: 26.5rem;

    @include media-breakpoint-up(xl) {
      width: 56rem;
    }
  }

  .swiper-slide-sm {
    width: 100%;
    height: 20.5rem;

    @include media-breakpoint-up(lg) {
      width: 43rem;
    }
  }

  .store-carousel-item {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4318102240896359) 33%, rgba(0, 0, 0, 0) 100%);
    }

    .store-carousel-content {
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 1;
      width: 70%;

      @include media-breakpoint-up(md) {
        bottom: 60px;
        left: 60px;
      }
    }
  }
}

.swiper-pagination {
  top: -45px;
  right: 20px;

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    margin: 0 6px;
  }

  .swiper-pagination-bullet-active {
    background: $primary;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.cart-drop {
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-width: 450px;
  }
}


.store-filters {
  @include media-breakpoint-down(lg) {
    background: $gray-100;
    padding: 2rem;
    border-radius: 5px;
  }
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-100;
  padding: 20px;
  width: 100px;
  height: 100px;
  border-radius: 6px;

  img {
    max-width: 100%;
    height: auto;
  }

  &.logo-box--lg {
    width: 140px;
    height: 140px;
    padding: 30px;
  }
}

.store-gallery {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 10px;
  margin-bottom: 3rem;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-100;
    padding: 10px;
    border-radius: 5px;

    img {
      max-width: 100%;
    }
  }
}

.ticket-data-container {
    padding: 1em;
}

.ticket-data {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
    padding-top: 1em;
    margin-bottom: 1em;
    background: $gray-100;
    border-radius: 6px;
    padding: 1em;
}

.tickets-header {
    display: flex;
    justify-content: center;
}
