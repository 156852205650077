.store-search {
  position: relative;
  max-width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  height: 56px;
  margin: 0 5px;
  border-radius: 99px;

  @include media-breakpoint-up(xl) {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media (min-width: 1550px) {
    max-width: 1500px;
    margin: 0 auto;
  }

  input {
    width: 100%;
    height: 56px;
    border: 0;
    background: transparent;
    color: #fff;

    &:focus {
      outline: 0;
      border: 0;
    }

    &::placeholder {
      color: #fff;
      opacity: .4;
    }
  }

  button[type="submit"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 99px;
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;
  }

  .form-control {
    &:focus {
      color: #fff;
      background-color: inherit;
      border-color: inherit;
      outline: inherit;
      box-shadow: inherit;
    }
  }
}
