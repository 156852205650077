.drop-wrapper {
  position: relative;
}

.drop {
  @include media-breakpoint-up($mega-breakpoint-up) {
    position: absolute;
    top: calc(100% + 14px);
    min-width: 200px;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, .1);
    border-radius: 3px;
    z-index: 99;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &::before {
      top: -9px;
      border-bottom: 8px solid $gray-300;
    }

    &::after {
      top: -7px;
      border-bottom: 8px solid #fff;
    }

    &.drop--left {
      left: 0;

      &::before,
      &::after {
        left: 10px;
      }
    }

    &.drop--center {
      left: 50%;
      transform: translateX(-50%);

      &::before,
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.drop--right {
      right: 0;

      &::before,
      &::after {
        right: 10px;
      }
    }
  }
}
