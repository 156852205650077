a.card {
  display: flex;

  * {
    pointer-events: none !important;
  }

  &:hover {
    color: inherit;

    .card-title {
      color: $primary;
    }
  }
}

.card-title {
  @extend .lead-bold;
  @extend .text-lead;
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: (-$card-spacer-y / 2) - .4rem;
  margin-bottom: $card-spacer-y;
}

.card-text {
  margin-top: $card-spacer-y;
  margin-bottom: $card-spacer-y;
}

.card-text:first-child {
  margin-top: 0;
}


// Dark mode

.card.card--inv {
  .card-title {
    @extend .text-inv-lead;
  }

  .card-subtitle,
  .card-text {
    @extend .text-inv-base;
  }
}


// Bigger cards padding

.card-body--lg {
  @include media-breakpoint-up(lg) {
    padding: $card-spacer-y * 3 $card-spacer-x * 2;
  }
}

.card-body--xl {
  @include media-breakpoint-up(lg) {
    padding: $card-spacer-y * 4.5 $card-spacer-x * 3.5;
  }
}


// Cards grid

.cards {
  display: grid;
  gap: 1.9rem;

  &.cards--list .card:not(:last-child) {
    margin-bottom: .5rem;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: $gray-200;
      transform: translateY(1.1rem);
    }
  }

  &.cards--xl-2 {
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(2, auto);
    }
  }

  &.cards--xl-3 {
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(3, auto);
    }
  }

  &.cards--xl-4 {
    @include media-breakpoint-only(xl) {
      grid-template-columns: repeat(4, auto);
    }
  }

  &.cards--lg-2 {
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(2, auto);
    }
  }

  &.cards--lg-3 {
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(3, auto);
    }
  }

  &.cards--lg-4 {
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(4, auto);
    }
  }

  &.cards--md-2 {
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(2, auto);
    }
  }

  &.cards--md-3 {
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(3, auto);
    }
  }

  &.cards--md-4 {
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(4, auto);
    }
  }

  &.cards--sm-2 {
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(2, auto);
    }
  }

  &.cards--sm-3 {
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(3, auto);
    }
  }

  &.cards--sm-4 {
    @include media-breakpoint-only(sm) {
      grid-template-columns: repeat(4, auto);
    }
  }

  &.gap--xl-large {
    @include media-breakpoint-only(xl) {
      gap: 60px 90px;
    }
  }

  &.gap--lg-large {
    @include media-breakpoint-only(lg) {
      gap: 60px 90px;
    }
  }

  &.gap--md-large {
    @include media-breakpoint-only(md) {
      gap: 60px 90px;
    }
  }

  &.gap--xl-larger {
    @include media-breakpoint-only(xl) {
      gap: 70px 120px;
    }
  }

  &.gap--lg-larger {
    @include media-breakpoint-only(lg) {
      gap: 70px 120px;
    }
  }

  &.gap--md-larger {
    @include media-breakpoint-only(md) {
      gap: 70px 120px;
    }
  }
}


// Blank basic card

.card {
  &.card--basic {
    border: 0;

    .card-body {
      padding: 0;
    }
  }
}


// Card with icon

.card {
  .card-icon-grid {
    display: flex;
  }

  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-only($breakpoint) {
      .card-icon-grid--#{$breakpoint} {
        display: flex;
      }
    }
  }

  .card-icon {
    position: relative;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
    margin: 1.5rem 2rem;

    &.card-icon--lg {
      width: 100px;
      height: 100px;
    }

    .card-icon-blob {
      position: absolute;
      top: 0;
      right: .5rem;
    }
  }

  .card-icon:first-child {
    margin-left: 0;
    margin-top: 0;
  }

  .card-icon:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}
