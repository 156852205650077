.bg {
  position: relative;
  overflow: hidden;

  .bg-content {
    position: relative;
    z-index: 1;
  }

  .bg-svg {
    position: absolute;
    left: 0;
    z-index: -1;
  }

  &.bg--center-top {
    .bg-svg {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bg--center-center {
    .bg-svg {
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  &.bg--center-bottom {
    .bg-svg {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bg--fixed {
    .bg-svg {
      @include media-breakpoint-up(xl) {
        bottom: 19rem;
        left: 50%;
        transform: translateX(-50%);
      }

      @include media-breakpoint-down(lg) {
        top: -700px;
      }
    }
  }
}
