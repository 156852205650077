@include media-breakpoint-down($mega-breakpoint-down) {
  body.mega--open {
    overflow: hidden;
  }
}

.mega {
  position: relative;
  z-index: 9000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-down($mega-breakpoint-down) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(#fff, 0.98);
    overflow-x: hidden;
    overflow-y: scroll;

    &:not(.mega--open) {
      display: none;
    }
  }

  @include media-breakpoint-up($mega-breakpoint-up) {
    flex-direction: column-reverse;

    &.mega--shadow {
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.02), 0 1px 0 rgba(0, 0, 0, .06);
    }
  }

  .mega-wrapper {
    &.mega-wrapper--main {
      @include media-breakpoint-up($mega-breakpoint-up) {
        background: #fff;
      }
    }

    &.mega-wrapper--info {
      background: $gray-100;
    }
  }

  .mega-bar {
    @include media-breakpoint-down($mega-breakpoint-down) {
      &.mega-bar--main {
        padding: 40px 7vw;
      }

      &.mega-bar--info {
        padding: 20px 7vw;
      }
    }

    @include media-breakpoint-up($mega-breakpoint-up) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &.mega-bar--main {
        //min-height: 110px;
        min-height: 7em;
      }

      &.mega-bar--info {
        min-height: 30px;
      }
    }
  }
}

.mega-mobile {
  padding: 20px ($grid-gutter-width / 2);

  @include media-breakpoint-up($mega-breakpoint-up) {
    display: none;
  }
}

.mega-toggle {
  position: fixed;
  top: 5px;
  right: 0;
  z-index: 9001;

  @include media-breakpoint-up($mega-breakpoint-up) {
    display: none;
  }
}
