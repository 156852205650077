.font-primary {
  font-family: $font-family-base;
}

.font-secondary {
  font-family: $secondary-font;
}

// Display and headings

.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: $secondary-font;
  color: $headings-color;
  margin-bottom: $spacing-margin-bottom;
    font-weight: 700;
}

h1, .h1 {
  @include media-breakpoint-up(md) {
    margin-bottom: $headings-margin-bottom * 1.7;
  }
}

h2, .h2 {
  @include media-breakpoint-up(md) {
    margin-bottom: $headings-margin-bottom * 1.4;
  }
}


// Fonts color variants

.text-base {
  color: $body-color;
}

.text-lead {
  color: $text-lead;
}

.text-inv-base {
  color: $text-inv-base;
}

.text-inv-lead {
  color: $text-inv-lead;
}

.text-inv-muted {
  color: $text-inv-muted;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .text-#{$breakpoint}-inv-base {
      color: $text-inv-base;
    }

    .text-#{$breakpoint}-inv-lead {
      color: $text-inv-lead;
    }

    .text-#{$breakpoint}-inv-muted {
      color: $text-inv-muted;
    }
  }
}


// Fonts size variants

.lead {
  @include media-breakpoint-up(lg) {
    line-height: 1.6;
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-base;
    font-weight: $font-weight-base;
  }
}

.large {
  @include media-breakpoint-up(lg) {
    font-size: $large-font-size;
    font-weight: $font-weight-light;
  }
  @include media-breakpoint-down(md) {
    font-size: $font-size-base;
  }
}

.lead-bold {
  @extend .lead;
  font-weight: $font-weight-bold;
}

.large-bold {
  @extend .large;
  font-weight: $font-weight-bold;
}

.base {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

.base-bold {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.small {
  font-weight: $font-weight-normal;
}

.small-bold {
  @extend .small;
  font-weight: $font-weight-bold;
}

.xsmall {
  font-size: $font-size-xs;
  font-weight: $font-weight-normal;
}

.xsmall-bold {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}


// Links

a {
  transition: all .15s;
}

.a-line {
  border-bottom: 1px dashed rgba($body-color, 0.5);

  &:hover {
    border-color: $primary;
  }
}

.a-line--inv {
  border-bottom: 1px dashed rgba(#fff, 0.5);

  &:hover {
    border-color:#fff;
  }
}
