.breadcrumb {
  flex-wrap: nowrap;
  overflow: hidden;

  .breadcrumb-item {
    white-space: nowrap;
  }

  a {
    @extend .a-line;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    transform: translateY(-3px);
  }

  &.breadcrumb--inv {
    .breadcrumb-item a {
      @extend .a-line--inv;
      color: $text-inv-base;
    }

    .breadcrumb-item.active {
      color: $text-inv-muted;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMSIgdmlld0JveD0iMCAwIDE4IDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSIxOCIgaGVpZ2h0PSIxIiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjMiLz4KPC9zdmc+Cg==");
    }
  }
}
