.btn {
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.btn-light {
  background: #fff;
  color: $primary;

  &:hover,
  &:focus,
  &:active {
    color: $primary !important;
  }
}

.custom-control-label.custom-control-label--lg {
  padding-left: 30px;

  &::before {
    top: 0.35rem;
    left: -1.5rem;
    width: 2rem;
    height: 2rem;
  }

  &::after {
    top: .8rem;
    left: -1rem;
    width: 1rem;
    height: 1rem;
    background: no-repeat 50% / 80% 80%;
  }
}

// Filter box
.filters {
  position: relative;
  background: #fff;
  border-radius: 5px;
  @extend .shadow-sm;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  @include media-breakpoint-up(xl) {
    margin-top: -9.375rem;
  }

  .filters-item {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 25px;
    flex-basis: 200px;

    @include media-breakpoint-down(md) {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }
    }

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        border-right: 1px solid $gray-200;
      }
    }
  }
}

.filters-input {
  position: relative;

  label {
    display: block;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $small-font-size;
    color: $text-muted;
    margin: 0 0 3px 0;
  }

  input,
  select {
    border: 0;
    padding: 0;
    margin: 0;
    font-size: $large-font-size;
    color: $text-lead;
    background: transparent;
    width: 100%;
    border-radius: 0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $input-placeholder-color;
      opacity: 1;
    }
  }

  &.filters-input--select::after {
    content: '';
    position: absolute;
    bottom: 8px;
    right: 0;
    width: 10px;
    height: 10px;
    border-top: 5px solid #000;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  option[value=""]  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: red !important;
  }
}

.inline-radios {
    .form-check {
        display: inline-block;
        margin-right: 2rem;
    }
}

.not-required-radios {
    .form-check {
        .required::after {
            display: none;
        }
    }
}
