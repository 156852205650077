.menu {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;

  &.menu--main {
    @include media-breakpoint-down($mega-breakpoint-down) {
      flex-direction: column;
    }

    @include media-breakpoint-up($mega-breakpoint-up) {
      align-items: center;
    }

    > .menu-item {
      margin: 0;

      @include media-breakpoint-only(lg) {
        margin: 0;
      }

      > .menu-link {
        display: block;
        padding: 1rem 1.6rem;
        margin: 0 .1rem;
        border-radius: 5px;
        border: 0;
        font-family: $secondary-font;
        text-transform: uppercase;
        color: $text-lead;
        letter-spacing: .5px;
        font-weight: 500;
        font-size: .82rem;
        transition: all .07s;

        @include media-breakpoint-only(lg) {
          padding: 16px 12px;
          margin: 0;
        }

        &:hover {
          color: $primary;
        }

        &:focus {
          background: $gray-100;
        }

        &:active {
          background: rgba($gray-200, .5);
        }

        &.active {
          color: $primary;
        }

        &.menu-link--basic {
          @include media-breakpoint-up($mega-breakpoint-up) {
            text-transform: unset;
            font-family: $font-family-base;
            padding: 16px 16px;
            font-weight: $font-weight-normal;
            font-size: $font-size-base;
          }
        }
      }

      &.menu-item-separator {
        @include media-breakpoint-down($mega-breakpoint-down) {
          display: none;
        }

        @include media-breakpoint-up($mega-breakpoint-up) {
          padding: 16px 26px;
          color: $text-muted;
        }
      }
    }
  }

  &.menu--sub {
    > .menu-item {
      margin: 0 8px;

      > .menu-link {
        font-size: $small-font-size;
      }
    }
  }
}
