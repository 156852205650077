.footer {
  .footer-main {
    background: $secondary;
    padding-top: 5rem;
    padding-bottom: 3rem;
  }

  .footer-copy {
    background: darken($secondary, 3%);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  // Grid
  .footer-grid {
    @include media-breakpoint-only(xs) {
      column-count: 2;
    }

    @include media-breakpoint-up(sm) {
      column-count: 3;
    }

    @include media-breakpoint-up(xl) {
      column-count: 4;
    }

    > div[class^="footer-grid--"] {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    // Equal width overwrites
    @include media-breakpoint-only(xl) {
      .footer-grid--about {
        padding-left: 30px;
      }
    }

    @include media-breakpoint-only(lg) {
      .footer-grid--about,
      .footer-grid--developer {
        padding-left: 15px;
      }
    }
  }


  // List Styling
  .footer-grid {
    > div[class^="footer-grid--"] {
      margin-bottom: 30px;

      li {
        font-size: $small-font-size;
        color: $text-inv-muted;
        padding: 4px 0;
      }

      li.footer-lead {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        color: $text-inv-lead;
        padding: 4px 0 8px 0;
      }
    }
  }
}


// Footer CTA
.footer-cta {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    background: $secondary;
    z-index: -1;
  }
}
