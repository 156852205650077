.table.table-list {
  margin-bottom: 0;

  tr:first-child td {
    border-top: 0;
  }

  tr {
    td:first-child {
      padding-left: 0;
    }

    td:last-child {
      padding-right: 0;
    }
  }
}

.table-compare {
  td {
    border: 0;
    padding: 20px 35px;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    &:not(:first-child) {
      text-align: center;
      width: 25rem;
    }
  }

  tbody > tr {
    td {
      border-left: 2px solid $gray-200;

      &:first-child,
      &.compare-secondary,
      &.compare-primary {
        border-left: 0;
      }
    }

    &:nth-child(odd) > td {
      background: $gray-100;
    }

    &:nth-child(even) > td {
      background: #fff;
    }

    &:nth-child(odd) > td.compare-secondary {
      background: lighten($secondary, 4%);
      border-radius: 0;
    }

    &:nth-child(even) > td.compare-secondary {
      background: $secondary;
      border-radius: 0;
    }

    &:nth-child(odd) > td.compare-primary {
      background: lighten($primary, 4%);
      border-radius: 0;
    }

    &:nth-child(even) > td.compare-primary {
      background: $primary;
      border-radius: 0;
    }
  }

  thead > tr {
    td {
      border-radius: 5px 5px 0 0 !important;
      vertical-align: bottom;
      padding: 40px 30px;
    }

    td.compare-secondary {
      background: $secondary;
    }

    td.compare-primary {
      background: $primary;
    }
  }

  tfoot > tr {
    td {
      border-radius: 0 0 5px 5px !important;
    }

    td.compare-secondary {
      background: $secondary;
    }

    td.compare-primary {
      background: $primary;
    }
  }
}
