.hero {
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 5rem;
  }

  &.hero--spaceless {
    margin-bottom: 0;
  }

  .hero-breadcrumb {}

  .hero-body {
    padding-bottom: 50px;
  }
}


// Over hero
.hero.hero--over {
  margin-bottom: 0;
  padding-bottom: 5rem;
}

.over-hero {
  margin-top: -5rem;
}

.hero.hero--over--lg {
  margin-bottom: 0;
  padding-bottom: 15rem;
}

.over-hero--lg {
  margin-top: -15rem;
}

